import { useFetch } from 'react-admin-base';
import { ActionsColumn, Column, CRUD, CRUDActions, IdColumn } from "react-admin-base-bootstrap";
import { Breadcrumb } from 'react-admin-base-nazox';
import { useUser } from '../../Components/UserProvider';
import CampaignEntity from "./CampaignEntity";

export default function Campaigns() {
	const user = useUser();
	const [ languages ] = useFetch("/campaign?itemPerPage=-1");
	
	if (!languages)
		return null;
	
	return <Breadcrumb
		title="Campaigns"
		data={
			[
				{
					href: '/campaign',
					name: 'Campaign'
				}
			]
		}
	>
		<CRUD Component={CampaignEntity} url="/campaign">
			<thead>
				<tr>
					<IdColumn />
					<Column sort="name">Name</Column>
					<Column>Status</Column>
					<Column>Branch</Column>
					{ user.type === 512 && <Column>Company</Column> }
					<Column sort="start_at">Start Date</Column>
					<Column sort="end_at">End Date</Column>
					<Column sort="created_at">Created at</Column>
					<ActionsColumn />
				</tr>
			</thead>
			<tbody>
			{
				row => <tr key={row.id}>
					<td className="min text-center">{row.id}</td>
					<td>{row.name}</td>
					<td>{row.enabled ? 'Enabled' : 'Disabled'}</td>
					<td>{row.branch.name}</td>
					{ user.type === 512 && <td>{row.branch.company.name}</td> }
					<td>{row.start_at && new Date(row.start_at).toLocaleString()}</td>
					<td>{row.end_at && new Date(row.end_at).toLocaleString()}</td>
					<td>{new Date(row.created_at).toLocaleString()}</td>
					<CRUDActions
						id={row.id}
						edit
						del={"/campaign/" + row.id}
					/>
				</tr>
			}
			</tbody>
		</CRUD>
	</Breadcrumb>;
}
