import { App, Authorized, AuthProvider, ForgotPasswordRoute, LoginRoute, NotAuthorized } from 'react-admin-base';
import { Login, MainLayout, Reset } from 'react-admin-base-nazox';
import "react-admin-base-nazox/assets/app.css";
import { BrowserRouter } from 'react-router-dom';
import './App.scss';
import { UserProvider } from "./Components/UserProvider";
import LanguageProvider from "./Components/LanguageProvider";
import Footer from "./Footer";
import MenuHeader from "./MenuHeader";
import MenuSidebar from "./MenuSidebar";
import Router from "./Router";
import UploadConfig from "./UploadConfig";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
    dsn: "https://74677726e0d04c4c95bd9163339fab22@o861592.ingest.sentry.io/6264533",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

function BaseApp() {
    return (
        <BrowserRouter basename={process.env.REACT_APP_PATHBASE || undefined}>
            <App
                id="campaign"
                name="Campaign"
                endpoint={process.env.REACT_APP_ENDPOINT}
            >
                <AuthProvider
                    tokenEndpoint="/token"
                    client_id="3da4385d-5166-4c1f-9c4b-49afe82be47e"
                    client_secret="123456"
                >
                    <LanguageProvider>
                        <NotAuthorized>
                            <LoginRoute>
                                <Login/>
                            </LoginRoute>
                            <ForgotPasswordRoute>
                                <Reset/>
                            </ForgotPasswordRoute>
                        </NotAuthorized>
                        <Authorized>
                            <UserProvider>
                                <UploadConfig>
                                    <MainLayout>
                                        <MenuHeader/>
                                        <MenuSidebar/>
                                        <Router/>
                                        <Footer/>
                                    </MainLayout>
                                </UploadConfig>
                            </UserProvider>
                        </Authorized>
                    </LanguageProvider>
                </AuthProvider>
            </App>
        </BrowserRouter>
    );
}

export default BaseApp;
