
import { useFetch } from 'react-admin-base';
import { Breadcrumb } from 'react-admin-base-nazox';
import { ActionsColumn, Column, CRUD, CRUDActions, IdColumn } from "react-admin-base-bootstrap";
import { useUser } from '../../Components/UserProvider';
import BrandEntity from "./BrandEntity";

export default function Categories() {
	const user = useUser();
	
	return <Breadcrumb
		title="Brands"
		data={
			[
				{
					href: '/brand',
					name: 'Brands'
				}
			]
		}
	>
		<CRUD Component={BrandEntity} url="/brand">
			<thead>
				<tr>
					<IdColumn />
					<Column sort="name">Name</Column>
					<Column sort="created_at">Created at</Column>
					<ActionsColumn />
				</tr>
			</thead>
			<tbody>
			{
				row => <tr key={row.id}>
					<td className="min text-center">{row.id}</td>
					<td>{row.name}</td>
					<td>{new Date(row.created_at).toLocaleString()}</td>
					<CRUDActions
						id={row.id}
						edit
						del={"/brand/" + row.id}
					/>
				</tr>
			}
			</tbody>
		</CRUD>
	</Breadcrumb>;
}
