import { Breadcrumb } from 'react-admin-base-nazox';
import {
	Actions,
	ActionsColumn,
	BootstrapDataTable,
	Column,
	IdColumn,
	Relative
} from "react-admin-base-bootstrap";
import { Route, Routes } from "react-router-dom";
import { useUser } from "../../Components/UserProvider";
import LanguageEntity from "./LanguageEntity";

export default function Languages() {
	const user = useUser();

	return <Routes>
		<Route path=":id/edit" element={<LanguageEntity />} />
		<Route path="create" element={<LanguageEntity />} />
		<Route path="*" element={
			<Breadcrumb
				title="Languages"
				data={
					[
						{
							href: '/language',
							name: 'Languages'
						}
					]
				}
			>
				<BootstrapDataTable url="/language" add="/language/create">
					<thead>
						<tr>
							<IdColumn />
							<Column className="min text-center">Icon</Column>
							<Column sort="name">Name</Column>
							<Column sort="language_name">Language Name</Column>
							<Column sort="language_code" className="text-center">Language Code</Column>
							<ActionsColumn />
						</tr>
					</thead>
					<tbody>
					{
						row => <tr key={row.id}>
							<td className="min text-center">{row.id}</td>
							<td className="min text-center">
								{ row.icon && <Relative>
									<img src={row.icon.access_url} style={{ width: '24px', height: '24px' }} alt={row.name} />
								</Relative> }
							</td>
							<td>{row.name}</td>
							<td>{row.language_name}</td>
							<td className="text-center">{row.language_code}</td>
							<Actions
								edit={(user.type === 512 || (row.company && row.company.id === user.company.id)) && ("/language/" + row.id + "/edit")}
								del={(user.type === 512 || (row.company && row.company.id === user.company.id)) && "/language/" + row.id}
							/>
						</tr>
					}
					</tbody>
				</BootstrapDataTable>
			</Breadcrumb>
		} />
	</Routes>;
}