import { useCallback } from 'react';
import { UploadProvider, useAuth } from "react-admin-base";

export default function UploadConfig({ children }) {
    const [ api ] = useAuth();

    const uploader = useCallback(async function(name, blob, contentType, signal, progress) {
        try {
            var data = await api.tokenized.put('/file/' + encodeURIComponent(name), blob, {
                headers: { 'Content-Type': contentType },
                onUploadProgress: function(progressEvent) {
                    progress(progressEvent.loaded / progressEvent.total);
                },
                signal
            });

            return data.data;
        } catch (e) {
            //  if (!Axios.isCancel(e)) {
            //     console.error('Hata olustu, tekrar deneniyor..', e);
            //} else {
            console.log("Es wird abgesagt");
            // }

            throw e;
        }
    }, [ api ]);

    return <UploadProvider uploader={uploader}>
        { children }
    </UploadProvider>
}

