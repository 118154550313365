
import {FormGroup, Input, Label, ModalBody, Row, Col, Alert} from "reactstrap";
import {useEntity, useFetch} from "react-admin-base";
import {CheckBox, ModalEntityEditor, Relative, SingleFilePicker, Validator} from 'react-admin-base-bootstrap';
import { useUser } from '../../Components/UserProvider';
import { BranchSelect, CompanySelect, LanguageSelect } from "../../Components/Selects";
import { ModalEntityEditorSteps, Step, Steps } from "../../Components/Steps";
import { Field } from "../../Components/Fields";
import ThemeSelection from "../../Components/ThemeSelection";
import ProductSelection from "../../Components/ProductSelection";
import { useLanguage } from "../../Components/LanguageProvider";
import { useCallback, useEffect, useMemo, useState } from "react";
import JSZip from "jszip";

export default function CampaignEntity({ url, onReload, id }) {
	const [ language ] = useLanguage();
	const entity = useEntity('/campaign', id, {
		init: true,
		language
	});
	const [data, setData,, loading] = entity;
	
	if (!data) {
		return null;
	}
	
	console.log(data);
	if (data.init && id)
		return null;
	
	return <ModalEntityEditorSteps title="Campaign Management" size="xl" url={url} onReload={onReload} entity={entity}>
		<ModalBody>
			<Steps minStep={data.enabled && 2}>
				<Step translate="BASIC">
					<Row>
						<Col>
							<Field title="Branch Selection" type="required">
								<BranchSelect
									value={data.branch}
									onChange={branch => setData({ branch })}
								/>
							</Field>
						</Col>
						<Col>
							<Field title="Language" type="required">
								<LanguageSelect
									value={data.language}
									onChange={language => setData({ language })}
								/>
							</Field>
						</Col>
					</Row>
					<Field title="Campaign Name">
						<Input
							type="text"
							value={data.name}
							onChange={a => setData({ name: a.currentTarget.value })}
						/>
					</Field>
					<Row>
						<Col>
							<Field title="Start Date">
								<Input
									type="date"
									value={data.start_at}
									onChange={a => setData({ start_at: a.currentTarget.value })}
								/>
							</Field>
						</Col>
						<Col>
							<Field title="End Date">
								<Input
									type="date"
									value={data.end_at}
									onChange={a => setData({ end_at: a.currentTarget.value })}
								/>
							</Field>
						</Col>
					</Row>
					<Field title="Template Selection" type="required">
						<ThemeSelection
							companyId={data.branch && data.branch.company.id}
							value={data.template}
							onChange={template => setData({ template })}
						/>
					</Field>
				</Step>
				<Step translate="ADD_PRODUCTS">
					<ProductSelection
						languageId={data.language.id}
						value={data.products}
						onChange={products => setData({ products })}
						maxProducts={data.template && !data.template.is_multi && 1}
					/>
				</Step>
				<Step translate="PREVIEW">
					<Row className="justify-content-center">
						<Col md="8">
							<PreviewPage
								value={data}
								onChange={setData}
							/>
						</Col>
					</Row>
				</Step>
				<Step translate="DOWNLOAD">
					<PublishPage
						value={data}
						onChange={setData}
					/>
				</Step>
			</Steps>
		</ModalBody>
	</ModalEntityEditorSteps>;
}

function useZipFile(url) {
	const [ loading, setLoading ] = useState(false);
	const [ error, setError ] = useState(null);
	const [ data, setData ] = useState(null);
	
	const update = useCallback(async function() {
		if (!url) {
			setData(null);
			setError(null);
			setLoading(false);
		} else {
			setLoading(true);
			setError(null);
			const files = [];
			try {
				var zip = new JSZip();
				
				const response = await fetch(url);
				var data = await response.blob();
				
				await zip.loadAsync(data);
				
				for (var fileName in zip.files) {
					const file = zip.files[fileName];
					
					const blob = await file.async('blob');
					files.push({
						name: fileName,
						url: URL.createObjectURL(blob)
					});
				}
				
				setData(files);
			} catch(e) {
				console.error(e);
				setError(e);	
			} finally {
				setLoading(false);
			}
		}
	}, [setLoading, setError, url]);
	
	useEffect(function() {
		update();
	}, [update]);
	
	
	return useMemo(() => [data, loading, error], [data, loading, error]);
}

function Previews({ files }) {
	return <Row>
		{
			files.concat(files).map(a => <Col>
				<img src={a.url} className="img-fluid" alt={a.name} />
			</Col>)
		}
	</Row>
}

function PreviewPage({ value, onChange }) {
	const [ files, loading, error ] = useZipFile(process.env.REACT_APP_ENDPOINT?.replace(/\/$/, '') + value.preview_url);
	
	return <div>
		{ loading && <Alert color="warning"><i className="fas fa-spin fa-spinner" /> Loading...</Alert>}
		{ error && <Alert color="danger"><i className="fas fa-exclamation-circle" /> Error</Alert>}
		{ files && <Previews files={files} />}
		<hr />
		<FormGroup>
			<Validator name="Toggle" type="required|accepted">
				<CheckBox
					id="enabled"
					label="Make this campaign active."
					checked={!!value.enabled}
					onChange={a => onChange({ enabled: !value.enabled })}
					disabled={!!value.enabled}
				/>
			</Validator>
		</FormGroup>
	</div>;
}

function PublishPage({ value, onChange }) {
	return <Row>
		<Col md="6" className="text-center offset-md-3">
			<h3>Your files are ready.</h3>
		
			<a className="btn btn-primary" href={process.env.REACT_APP_ENDPOINT?.replace(/\/$/, '') + value.preview_url} target="_blank">
				Download
			</a>
		</Col>
	</Row>;
}

