
import {FormGroup, Input, Label, ModalBody} from "reactstrap";
import {useEntity} from "react-admin-base";
import {ModalEntityEditor, Validator} from "react-admin-base-bootstrap";
import { useUser } from '../../Components/UserProvider';
import { CompanySelect } from '../../Components/Selects';

export default function BranchEntity({ url, onReload, id }) {
	const user = useUser();
	const entity = useEntity('/branch', id);
	const [data, setData] = entity;

	if (!data) {
		return null;
	}

	return <ModalEntityEditor title="Branch Management" url={url} onReload={onReload} entity={entity}>
		<ModalBody>
			<FormGroup>
				<Label>Name</Label>
				<Validator name="name" type="required">
					<Input
						type="text"
						value={data.name || ''}
						onChange={a => setData({name: a.currentTarget.value})}
					/>
				</Validator>
			</FormGroup>
			<FormGroup>
				<Label>Address 1</Label>
				<Input
					type="text"
					value={data.address_line1 || ''}
					onChange={a => setData({address_line1: a.currentTarget.value})}
				/>
			</FormGroup>
			<FormGroup>
				<Label>Address 2</Label>
				<Input
					type="text"
					value={data.address_line2 || ''}
					onChange={a => setData({address_line2: a.currentTarget.value})}
				/>
			</FormGroup>
			<FormGroup>
				<Label>Phone</Label>
				<Input
					type="text"
					value={data.phone || ''}
					onChange={a => setData({phone: a.currentTarget.value})}
				/>
			</FormGroup>
			<FormGroup>
				<Label>Website (URL)</Label>
				<Input
					type="text"
					value={data.website_url || ''}
					onChange={a => setData({website_url: a.currentTarget.value})}
				/>
			</FormGroup>
			<FormGroup>
				<Label>Working Hours</Label>
				<Input
					type="text"
					value={data.working_hours || ''}
					onChange={a => setData({working_hours: a.currentTarget.value})}
				/>
			</FormGroup>
			{ user.type === 512 && <FormGroup>
				<Label>Company</Label>
				<CompanySelect
					value={data.company}
					onChange={data => setData({ company: data })}
				/>
			</FormGroup> }
		</ModalBody>
	</ModalEntityEditor>;
}
