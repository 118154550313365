import { Actions, ActionsColumn, Column, CRUD, CRUDActions, IdColumn } from "react-admin-base-bootstrap";
import { Breadcrumb } from 'react-admin-base-nazox';
import UserEntity, {roles} from "./UserEntity";
import { useUser } from "../../Components/UserProvider";

export default function Users() {
    const user = useUser();
    
    return <Breadcrumb
        title="User"
        data={
            [
                {
                    href: '/user',
                    name: 'User'
                }
            ]
        }
    >
        <CRUD url="/user" Component={UserEntity}>
            <thead>
                <tr>
                    <IdColumn/>
                    <Column sort="name">Name</Column>
                    <Column sort="email">E-Mail-Address</Column>
                    <Column sortww="type">Role</Column>
                    { user.type === 512 && <Column>Company</Column> }
                    <ActionsColumn/>
                </tr>
            </thead>
            <tbody>
            {
                row => <tr key={row.id}>
                    <td className="min text-center">{row.id}</td>
                    <td>{row.name}</td>
                    <td>{row.email}</td>
                    <td>{roles[row.type]}</td>
                    { user.type === 512 && <td>{(row.company && row.company.name) || '-'}</td> }
                    <Actions
                        edit={"/user/" + row.id + '/edit'}
                        del={"/user/" + row.id}
                    />
                </tr>
            }
            </tbody>
        </CRUD>
    </Breadcrumb>;
}
