import {
	Card,
	CardBody,
	Col,
	FormGroup,
	FormText,
	Input,
	Label,
	Nav,
	NavItem,
	NavLink,
	Row
} from "reactstrap";
import React, {useState} from "react";
import {useFetch, useFreeFetch} from "react-admin-base";

export default function TranslationManager({ value, onChange, parent }) {
	const [ mask, setMask ] = useState(-1);
	const [ keys ] = useFetch('/language/keys');
	const [ messages ] = useFreeFetch(parent && parent.precompiled_json && parent.precompiled_json.access_url);

	if (!keys)
		return null;

	return <>
		<Card>
			<CardBody>
				<Row>
					{ keys
						.map(b => <Col md="6" key={b.key}>
							<FormGroup>
								<Label>{b.key}</Label>
								<Input
									type="text"
									value={value[b.key] || ''}
									onChange={a => onChange({ ...value, [b.key]: a.currentTarget.value || null })}
									placeholder={messages && messages[b.key]}
								/>
								{ !!b.description && <FormText color="muted">
									{ b.description }
								</FormText> }
							</FormGroup>
						</Col>) }
				</Row>
			</CardBody>
		</Card>
	</>;
}