import {Menu, MenuGroup, Sidebar} from 'react-admin-base-nazox';
import { useUser } from './Components/UserProvider';
import {FormattedMessage} from "react-intl";

export default function MenuSidebar() {
    const user = useUser();
    
    return <Sidebar>

        <MenuGroup title="Company">
            { user.type >= 256 && <Menu title="Campaigns" to="/campaign" icon="fa-solid fa-tarp"/> }
            { user.type >= 256 && <Menu title="Branches" to="/branch" icon="fa-solid fa-cube"/> }
            { user.type === 512 && <Menu title="Companies" to="/company" icon="fa-solid fa-cubes"/> }
        </MenuGroup>

        { user.type === 512 && <MenuGroup title="Data Management">
            <Menu title="Brands" to="/brand" icon="fa-brands fa-bandcamp"/>
            <Menu title="Categories" to="/category" icon="fas fa-list"/>
            <Menu title="Products" to="/product" icon="fa-solid fa-shop"/>
            <Menu title="Languages" to="/language" icon="fas fa-language"/>
            <Menu title="Templates" to="/template" icon="fa-solid fa-table-columns"/>
        </MenuGroup> }

        <MenuGroup title={<FormattedMessage id="OTHER"/>}>
            { user.type === 512 && <Menu title={<FormattedMessage id="USERS"/>} to="/user" icon="fas fa-users"/>}
            { user.type >= 256 && <Menu title={<FormattedMessage id="MY_PROFILE"/>} to="/profile" icon="fas fa-user"/>}
        </MenuGroup>

    </Sidebar>;
}
