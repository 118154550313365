
import {FormGroup, Input, Label, ModalBody} from "reactstrap";
import {useEntity, useFetch} from "react-admin-base";
import {ModalEntityEditor, SingleFilePicker, Validator} from "react-admin-base-bootstrap";
import { useUser } from '../../Components/UserProvider';
import LanguageEdit from '../../Components/LanguageEdit';

export default function CategoryEntity({ url, onReload, id }) {
	const user = useUser();
	const entity = useEntity('/category', id, { names: {} });
	const [data, setData] = entity;
	
	if (!data) {
		return null;
	}

	return <ModalEntityEditor title="Category Management" url={url} onReload={onReload} entity={entity}>
		<ModalBody>
			<LanguageEdit value={data.names} onChange={names => setData({names})}>
				{
					(language, value, onChange) => <FormGroup>
						<Label>Name</Label>
						<Validator name={language.language_code + "_name"} type="required">
							<Input
								type="text"
								value={value || ''}
								onChange={b => onChange(b.currentTarget.value)}
							/>
						</Validator>
					</FormGroup>
				}
			</LanguageEdit>
		</ModalBody>
	</ModalEntityEditor>;
}
