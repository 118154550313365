import { useState } from "react";
import { useFetch } from "react-admin-base";
import { CheckBox, Relative } from "react-admin-base-bootstrap";
import { Col, Row } from "reactstrap";
import { useUser } from "./UserProvider";

export default function ThemeSelection({ companyId, value, onChange }) {
	const user = useUser();
	const [ isMulti, setIsMulti ] = useState(false);
	const company_id = companyId || (user.company && user.company.id);
	const [ templates ] = useFetch( company_id && ("/template?itemPerPage=-1&is_multi=" + (isMulti ? true : false) + "&company_id=" + company_id) );
	
	if (!company_id)
		return <div>Please select a branch first.</div>
	
	if (!templates)
		return <div>Loading...</div>
	
	return <div>
		<CheckBox id="single" type="radio" label="Tekli" checked={!isMulti} onClick={a => setIsMulti(false)} />
		<CheckBox id="multi" type="radio" label="Coklu" checked={!!isMulti} onClick={a => setIsMulti(true)} />
		
		<Row>
			{ templates.data.map(template => <Col key={template.id} onClick={a => onChange(template)} md="3">
				<h4 className="text-center">{ template.name }</h4>
				
				{
					(template.background || template.first_page) &&
						<Relative>
							<img className="img-fluid" src={(template.background || template.first_page).file.access_url} alt={template.name} />
						</Relative>
				}
				
				{ value && value.id === template.id
					? <div className="btn btn-primary d-block mt-2">Choosen</div> :
					<div className="btn btn-outline-primary d-block mt-2">Choose</div>}
			</Col>) }
		</Row>
	</div>;
}
