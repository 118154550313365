
import { useEntity } from "react-admin-base";
import { EntityEditor, SingleFilePicker, Validator } from "react-admin-base-bootstrap";
import { Breadcrumb } from 'react-admin-base-nazox';
import { Navigate, useParams } from "react-router-dom";
import { Card, CardBody, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { CompanySelect, LanguageSelect } from "../../Components/Selects";
import { useUser } from "../../Components/UserProvider";
import TranslationManager from "./TranslationManager";

export default function LanguageEntity() {
	const { id } = useParams();
	const user = useUser();
	const entity = useEntity('/language', id, { values: { } });
	const [data, setData] = entity;

	if (!data) {
		return null;
	}

	return <Breadcrumb
		title="Languages"
		data={
			[
				{
					href: '/language',
					name: 'Languages'
				},
				{
					href: id ? '/language/' + data.id + '/edit' : '/language/create',
					name: id ? 'Edit' : 'Create'
				}
			]
		}
	>
		{ !id && data.id && <Navigate to={"../" + data.id + "/edit"} replace />}
		<EntityEditor entity={entity}>
			<Card>
				<CardBody>
					<FormGroup>
						<Label>Name</Label>
						<Validator name="name" type="required">
							<Input
								type="text"
								value={data.name || ''}
								onChange={a => setData({name: a.currentTarget.value})}
							/>
						</Validator>
					</FormGroup>
					<Row>
						<Col md="9">
							<FormGroup>
								<Label>Language Name</Label>
								<Validator name="language_name" type="required">
									<Input
										type="text"
										value={data.language_name || ''}
										onChange={a => setData({language_name: a.currentTarget.value})}
									/>
								</Validator>
							</FormGroup>
						</Col>
						<Col md="3">
							<FormGroup>
								<Label>Language Code</Label>
								<Validator name="language_code" type="required">
									<Input
										type="text"
										value={data.language_code || ''}
										onChange={a => setData({language_code: a.currentTarget.value})}
									/>
								</Validator>
							</FormGroup>
						</Col>
					</Row>
					<FormGroup>
						<Label>Icon</Label>
						<SingleFilePicker
							accepts="image/*"
							value={data.icon}
							onChange={a => setData({icon: a})}
						/>
					</FormGroup>
					<FormGroup>
						<Label>Parent Language</Label>
						<LanguageSelect
							filter={row => !id || (row.id !== +id)}
							value={data.parent}
							onChange={a => setData({parent: a})}
						/>
					</FormGroup>
				</CardBody>
			</Card>
			<TranslationManager
				value={data.values}
				onChange={a => setData({ values: a })}
				parent={data.parent}
			/>
		</EntityEditor>
	</Breadcrumb>;
}
