
import React, { useContext, useReducer, useMemo, useState } from 'react';
import { useApp, useFreeFetch } from 'react-admin-base';
import { Relative } from 'react-admin-base-bootstrap';
import { IntlProvider } from 'react-intl';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const LanguageContext = React.createContext(null as any);

export function useLanguage() {
  return useContext(LanguageContext);
}

export default function LanguageProvider({ children }) {
  const app = useApp();
  const [ languages ] = useFreeFetch("/language?itemPerPage=-1");

  const languagesByCode = languages && languages.data.reduce((a, b) => ({ ...a, [b.language_code]: b }), {});

  const preferredLanguage = (navigator.languages && navigator.languages[0]) ||
	navigator.language;

  const [ lang, setLang ] = useReducer((oldValue, newValue) => {
	localStorage.setItem(app.id + "_" + 'language', newValue);
	return newValue;
  }, localStorage.getItem(app.id + "_" + 'language') || null);

  const activeLanguage = languagesByCode && (languagesByCode[lang] || languagesByCode['de']);

  const [ keys ] = useFreeFetch(activeLanguage && activeLanguage.precompiled_json && activeLanguage.precompiled_json.access_url );
  
  if (!activeLanguage)
  	return null;
  
  return <IntlProvider locale={activeLanguage.language_code} messages={keys}>
	<LanguageContext.Provider value={[activeLanguage, languagesByCode, setLang]}>
	  { children }
	</LanguageContext.Provider>
  </IntlProvider>;
}

export function LanguageSwitcher() {
  const [show, setShow] = useState(false);
  const [ activeLang, languages, setLanguage ] = useLanguage();

  return <Dropdown isOpen={show} toggle={() => setShow(!show)}>
	  <DropdownToggle className="nav-flag dropdown-toggle show" nav caret>
		  { activeLang && activeLang.icon && <Relative>
			  <img width="20" src={ activeLang.icon.access_url } alt={activeLang.name} />
		  </Relative> }
	  </DropdownToggle>
	  <DropdownMenu end>
		  { Object.entries(languages).map(([key, value] : any) => <DropdownItem key={key} active={activeLang === value} onClick={() => setLanguage(key)}>
			  <Relative><img width="20" src={value.icon && value.icon.access_url} alt={value.name} /></Relative> <span className="align-middle">{ value.name }</span>
		  </DropdownItem>) }
	  </DropdownMenu>
  </Dropdown>;
}