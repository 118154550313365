
import {FormGroup, Input, Label, ModalBody} from "reactstrap";
import {useEntity} from "react-admin-base";
import {ModalEntityEditor, Validator} from 'react-admin-base-bootstrap';
import {useUser} from "../../Components/UserProvider";
import { CompanySelect } from "../../Components/Selects";

export const roles = {
    '512': 'Admin',
    '256': 'Company Admin',
    '128': 'User'
};

export default function UserEntity({url, onReload, id}) {
    const user = useUser();
    const entity = useEntity('/user', id, { type: user.type });
    const [data, setData] = entity;

    if (!data) {
        return null;
    }

    return <ModalEntityEditor title="User Management" url={url} onReload={onReload} entity={entity}>
        <ModalBody>
            <FormGroup>
                <Label>Name</Label>
                <Validator name="name" type="required">
                    <Input
                        type="text"
                        value={data.name}
                        onChange={a => setData({name: a.currentTarget.value})}
                    />
                </Validator>
            </FormGroup>

            <FormGroup>
                <Label>E-Mail Address</Label>
                <Validator name="email" type="required">
                    <Input type="text" value={data.email}
                           onChange={a => setData({email: a.currentTarget.value})}/>
                </Validator>
            </FormGroup>

            <FormGroup>
                <Label>Password</Label>
                <Input type="password" value={data.password}
                       autocomplete="new-password"
                       onChange={a => setData({password: a.currentTarget.value})}/>
            </FormGroup>
            
            <FormGroup>
                <Label>Role</Label>
                <Input
                    type="select"
                    value={data.type}
                    onChange={a => setData({type: +a.currentTarget.value, supplier:null})}
                >
                    { Object.entries(roles).filter(([id]) => id <= user.type).map(([id, role]) => <option value={id} key={id}>{role}</option>)}
                </Input>
            </FormGroup>
            
            { user.type == 512 && data.type != 512 && <FormGroup>
                <Label>Company</Label>
                <Validator name="company" type="required">
                    <CompanySelect
                        value={data.company}
                        onChange={company => setData({ company })}
                    />
                </Validator>
            </FormGroup> }
        </ModalBody>
    </ModalEntityEditor>;
}
