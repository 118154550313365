
import { Breadcrumb } from "react-admin-base-nazox";
import { ActionsColumn, Column, CRUD, CRUDActions, IdColumn } from "react-admin-base-bootstrap";
import { useUser } from '../../Components/UserProvider';
import BranchEntity from "./BranchEntity";
import {FormattedMessage} from "react-intl";

export default function Branches() {
	const user = useUser();
	
	return <Breadcrumb
		title="Branches"
		data={
			[
				{
					href: '/branch',
					name: 'Branches'
				}
			]
		}
	>
		<CRUD Component={BranchEntity} url="/branch">
			<thead>
				<tr>
					<IdColumn />
					<Column sort="name">{<FormattedMessage id="GENERAL_NAME"/>}</Column>
					<Column sort="created_at">{<FormattedMessage id="CREATED_AT"/>}</Column>
					{ user.type === 512 && <Column>Company</Column> }
					<ActionsColumn />
				</tr>
			</thead>
			<tbody>
			{
				row => <tr key={row.id}>
					<td className="min text-center">{row.id}</td>
					<td>{row.name}</td>
					<td>{new Date(row.created_at).toLocaleString()}</td>
					{ user.type === 512 && <td>{row.company.name}</td> }
					<CRUDActions
						id={row.id}
						edit
						del={"/branch/" + row.id}
					/>
				</tr>
			}
			</tbody>
		</CRUD>
	</Breadcrumb>;
}
