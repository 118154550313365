import { useCallback, useMemo, useState } from "react";
import { MultiValue, useDataTable, useFetch, ValidatorProvider } from "react-admin-base";
import { CheckBox, Relative } from "react-admin-base-bootstrap";
import BootstrapPagination from "react-admin-base-bootstrap/lib/esm/Components/BootstrapPagination";

import { Button, Col, Form, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import { Field } from "./Fields";
import { useLanguage } from "./LanguageProvider";
import { CategorySelect } from "./Selects";
import { useUser } from "./UserProvider";

export default function ProductSelection({ languageId, value, onChange, maxProducts }) {
	const [ lang ] = useLanguage();
	const [ modalOpen, setModalOpen ] = useState(false);

	return <Row>
		<Col>
			<ProductFinder value={value} onChange={onChange} languageId={languageId} maxProducts={maxProducts} />
		</Col>
	</Row>;
}

function ProductFinder({ value, onChange, languageId, maxProducts }) {
	const [ searchTerm, setSearchTerm ] = useState(''); 
	const [ category, setCategory ] = useState();
	
	const params = useMemo(() => ({ query: searchTerm, language_id: languageId, category_id: (category && category.id) || null }), [searchTerm, category, languageId]);
	
	const [ currentPage, lastPage, setPage, data, itemPerPage, setItemPerPage ] = useDataTable('/product', params);
	
	return <div>
		<Row>
			<Col>
				<Field title="Search Term">
					<Input
						type="text"
						bsSize="lg"
						value={searchTerm}
						onChange={a => setSearchTerm(a.currentTarget.value)}
					/>
				</Field>
			</Col>
			<Col md="3">
				<Field title="Category">
					<CategorySelect
						value={category}
						onChange={setCategory}
					/>
				</Field>
			</Col>
		</Row>
		
		<Row>
			{
				(value || []).map(row => <Col md="3" className="d-flex flex-grow-1 flex-column my-3" key={row.product.id}>
					<SingleProduct
						product={row.product}
						selection={row}
						remove={() => onChange((value || []).filter(b => b.product.id !== row.product.id))}
						add={false}
						languageId={languageId}
					/>
				</Col>)
			}
			{
				data && data
					.filter(row => !value || value.findIndex(b => b.product.id === row.id) === -1)
					.map(row => <Col md="3" className="d-flex flex-grow-1 flex-column my-3" key={row.id}>
						<SingleProduct
							product={row}
							add={maxProducts && (value || []).length >= maxProducts ? null : data => onChange((value || []).concat([data]))}
							remove={false}
							selection={null}
							languageId={languageId}
						/>
					</Col>)
			}
		</Row>
		
		{ lastPage > 1 && <nav className="mt-3">
			<BootstrapPagination
				currentPage={currentPage}
				pageCount={lastPage}
				onPageChange={index => setPage(index)}
			/>
		</nav> }
	</div>;
}

function SingleProduct({ product, selection, add, remove, languageId }) {
	const [ isSelected, setIsSelected ] = useState(false);
	
	return <>
		{ isSelected && <ProductSelectionModal
				product={product}
				onSubmit={data => {
					if (data) {
						add(data);
					}
					
					setIsSelected(false);
				}}
			/> }
	
		<h4>{ product.names[languageId] && product.names[languageId].name }</h4>
		<h5>{ product.names[languageId] && product.names[languageId].subtitle }</h5>
		
		<div className="flex-grow-1">
		{
			product.photo &&
				<Relative>
					<img className="img-fluid" src={product.photo.access_url} alt="Product Image" />
				</Relative>
		}
		</div>
		
		{ selection ? <Button type="button" color="danger" block className="mt-2" onClick={remove}>Delete</Button> :
			add ? <Button type="button" color="primary" block outline className="mt-2" onClick={() => setIsSelected(true)}>Choose</Button> :
		   	 <Button type="button" color="primary" block outline className="mt-2" disabled>Max Product Selected</Button>}
	</>;
}

function ProductSelectionModal({ product, onSubmit }) {
	const [ price, setPrice ] = useState('');
	const [ oldPrice, setOldPrice ] = useState('');
	
	const onFormSubmit = useCallback(function(e) {
		e.preventDefault();
		e.stopPropagation();
		
		onSubmit({
			product,
			price,
			old_price: oldPrice || null
		});
	}, [price, oldPrice, product]);
	
	return <Modal isOpen toggle={a => onSubmit(null)}>
		<ModalHeader toggle={a => onSubmit(null)}>
			Add product
		</ModalHeader>
		<ValidatorProvider>
			<Form onSubmit={onFormSubmit}>
				<ModalBody>
					<Field title="Price" type="required|numeric">
						<Input
							type="text"
							value={price}
							onChange={a => setPrice(a.currentTarget.value)}
						/>
					</Field>
					<Field title="Old Price" type="numeric">
						<Input
							type="text"
							value={oldPrice}
							onChange={a => setOldPrice(a.currentTarget.value)}
						/>
					</Field>
				</ModalBody>
				<ModalFooter>
					<Button type="submit" block color="primary">
						Submit	
					</Button>
				</ModalFooter>
			</Form>
		</ValidatorProvider>
	</Modal>;
}
