import React, {useCallback, useState} from 'react';
import {ApiSelect, Relative} from "react-admin-base-bootstrap";
import {makeSlug, useAuth} from 'react-admin-base';
import CompanyEntity from '../pages/Company/CompanyEntity';
import { useLanguage } from './LanguageProvider';
import BrandEntity from '../pages/Brand/BrandEntity';
import BranchEntity from '../pages/Branch/BranchEntity';

export function CreateSelect(props) {
    const { Component, onChange, value, isMulti } = props;

    const [ isOpen, setIsOpen ] = useState(false);

    const onReload = useCallback(function(data) {
        setIsOpen(false);
        data && onChange(isMulti ? (value || []).concat([data]) : data);
    }, [onChange, setIsOpen, value, isMulti]);

    return <>
        { isOpen && <Component id={value && value.id} onReload={onReload} /> }
        <ApiSelect {...props} onAddOrEdit={() => setIsOpen(true)} />
    </>;
}

export function FreeSelect(props) {
    const { value, onChange, options, isMulti } = props;
    
    const onCreateOption = useCallback(function (input) {
        return {
            id: input,
            name: input
        };
    }, []);
    
    const fnc = a => ({ id: a, name: a });
    
    return <ApiSelect
        {...props}
        value={isMulti ? (value || []).map(fnc) : (value && fnc(value))}
        onChange={a => onChange((a && (isMulti ? a.map(a => a.id) : a.id))) || null}
        staticOptions={(options && options.map(fnc)) || []}
        onCreateOption={onCreateOption}
    />;
}

export function MapSelect(props) {
    const { value, onChange, staticOptions } = props;

    return <ApiSelect
        {...props}
        staticOptions={Object.entries(staticOptions).map(a => ({ id: +a[0], name: a[1] }))}
        value={value && { id: +value, name: staticOptions[value] }}
        onChange={a => onChange(a && a.id)}
    />;
}

export function CompanySelect(props) {
    return <CreateSelect url="/company" Component={CompanyEntity} {...props} />;
}

export function BranchSelect(props) {
    return <CreateSelect url="/branch" Component={BranchEntity} {...props} />;
}
    
export function WebsiteSelect(props) {
    return <ApiSelect url="/website" {...props}>
        { row => <>
            { row.language && row.language.icon && <Relative><img style={{ width: '16px', height: '16px', marginRight: '5px' }} src={row.language.icon.access_url} alt={row.language.name} /></Relative> }
            { row.name }
        </>}
    </ApiSelect>;
}

export function LanguageSelect(props) {
    return <ApiSelect url="/language" {...props}>
        { row => <>
            { row.icon && <Relative><img style={{ width: '16px', height: '16px', marginRight: '5px' }} src={row.icon.access_url} alt={row.name} /></Relative> }
            { row.name }
        </>}
    </ApiSelect>;
}

export function UserSelect(props) {
    return <ApiSelect
        url="/user"
        {...props}
    />;
}

export function BrandSelect(props) {
    return <CreateSelect url="/brand" Component={BrandEntity} {...props} />;
}

export function CategorySelect(props) {
    const [ lang ] = useLanguage();
    return <ApiSelect url="/category" {...props}>
        { row => <>
            { (row && row.names[lang.id]) || '' }
        </>}
    </ApiSelect>;
}

export function FontSelect(props) {
    return <ApiSelect url="/font" {...props} />;
}
