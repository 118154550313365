
import {FormGroup, Input, Label, ModalBody} from "reactstrap";
import {useEntity, useFetch} from "react-admin-base";
import {ModalEntityEditor, SingleFilePicker, Validator} from "react-admin-base-bootstrap";
import { useUser } from '../../Components/UserProvider';
import LanguageEdit from '../../Components/LanguageEdit';

export default function BrandEntity({ url, onReload, id }) {
	const user = useUser();
	const entity = useEntity('/brand', id, { names: {} });
	const [data, setData] = entity;
	
	if (!data) {
		return null;
	}

	return <ModalEntityEditor title="Brand Management" url={url} onReload={onReload} entity={entity}>
		<ModalBody>
			<FormGroup>
				<Label>Name</Label>
				<Validator name="name" type="required">
					<Input
						type="text"
						value={(data && data.name) || ''}
						onChange={b => setData({ name: b.currentTarget.value })}
					/>
				</Validator>
			</FormGroup>
		</ModalBody>
	</ModalEntityEditor>;
}
