import React, { createContext, useCallback, useContext } from "react";
import { useState } from "react";
import { ValidatorProvider } from "react-admin-base";
import { Navigate, useNavigate } from "react-router-dom";
import { Alert, Button, Card, CardBody, CardHeader, Col, Form, Modal, ModalFooter, ModalHeader, Nav, NavLink, Row } from "reactstrap";
import { StepList, StepItem } from 'react-admin-base-bootstrap';

/*
<ul id="addUserStepFormProgress" class="js-step-progress step step-sm step-icon-sm step step-inline step-item-between mb-3 mb-md-5">
  <li class="step-item active focus">
    <a class="step-content-wrapper" href="javascript:;" data-hs-step-form-next-options="{
        &quot;targetSelector&quot;: &quot;#addUserStepProfile&quot;
      }">
      <span class="step-icon step-icon-soft-dark">1</span>
      <div class="step-content">
        <span class="step-title">Profile</span>
      </div>
    </a>
  </li>

  <li class="step-item">
    <a class="step-content-wrapper" href="javascript:;" data-hs-step-form-next-options="{
        &quot;targetSelector&quot;: &quot;#addUserStepBillingAddress&quot;
      }">
      <span class="step-icon step-icon-soft-dark">2</span>
      <div class="step-content">
        <span class="step-title">Billing address</span>
      </div>
    </a>
  </li>

  <li class="step-item">
    <a class="step-content-wrapper" href="javascript:;" data-hs-step-form-next-options="{
        &quot;targetSelector&quot;: &quot;#addUserStepConfirmation&quot;
      }">
      <span class="step-icon step-icon-soft-dark">3</span>
      <div class="step-content">
        <span class="step-title">Confirmation</span>
      </div>
    </a>
  </li>
</ul>

*/

export function Steps({ minStep, children }) {
    const [ active, setActive ] = useState(minStep || 0);
    const [ error, setError ] = useState(null as any);
    
    const lastPage = React.Children.count(children);
    const submit = useContext(SubmitContext);
    
    const nextStep = useCallback(async function(e) {
        e.preventDefault();
        
        try {
            await submit(e);
            setActive(active => active + 1);
        } catch (e: any) {
            console.error(e);
            setError((e.response && e.response.data && e.response.data.message) || e.data || e.message || e);
        }       
    }, [submit, setActive, setError]);

    return <div>
        <StepList active={active} setActive={i => (!minStep || i >= minStep) && active > i && setActive(i)}>
          { React.Children.map(children, (a, i) => <StepItem translate={a.props.translate} disabled={i < minStep} />) }
        </StepList>
        <div>
            <ValidatorProvider>
                <Form onSubmit={nextStep}>
                    {
                        React.Children.map(children, (a,i) => React.cloneElement(a, { active: active === i }))
                    }
                    <div className="mt-2">
                        { error && <Alert color="danger" toggle={a => setError(null)}>{error}</Alert>}
                        <Row>
                            { active !== 0 && (!minStep || active < minStep) && <Col>
                                <Button type="button" color="primary" block outline onClick={a => setActive(a => a - 1)}>Prev</Button>
                            </Col> }
                            { active !== lastPage - 1 && <Col>
                                <Button color="primary" block>Next</Button>
                            </Col> }
                        </Row>
                    </div>
                </Form>
            </ValidatorProvider>
        </div>
    </div>;
}

type TabParams = {
    translate: string;
    children?: any;
    active?: boolean;
};

export function Step({ translate, children, active }: TabParams) {
    if (!active)
        return null;
        
    return <div>
        { children }
    </div>;
}

const EntityContext = createContext(null as any);
const SubmitContext = createContext(null as any);


export function ModalEntityEditorSteps({ entity, title, size, url, onReload, disabled, children }) {
        const [ data, , save, loading ] = entity;
    
        const [ open, setOpen ] = useState(true);
        const [ saved, setSaved ] = useState(false);
        const [ error, setError ] = useState<any>(false);
            
        //const history = useHistory();
        
        const hasId = !!data.id;
    
        const onSubmit = useCallback(async function() {
            if (saved) {
                setSaved(false);
            }
    
            if (error) {
                setError(null);
            }
    
            try {
                let data = await save();
                if (onReload) {
                    await onReload(data);
                }
    
                if (url) {
                    setSaved(true);
                    if (!hasId) {
                        window.history.replaceState(null, "", url + "/" + data.id + "/edit");
                    }
                }
            } catch(e: any) {
                //console.error(e);
                //setError((e.response && e.response.data && e.response.data.message) || (error.data && error.data.message) || e.data || e.message || e);
                throw e;
            }
            finally
            {
    
            }
        }, [hasId, save, saved, error, onReload, url]);
    
        return <>
            { !open && <Navigate to={url} replace /> }
            <Modal isOpen size={size} toggle={() => url ? setOpen(false) : onReload(null)} fade={false}>
                { title && <ModalHeader toggle={() => url ? setOpen(false) : onReload(null)}>
                    <b>{ title }</b>
                </ModalHeader> }
                <SubmitContext.Provider value={onSubmit}>
                    <EntityContext.Provider value={entity}>
                        { children }
                    </EntityContext.Provider>
                </SubmitContext.Provider>
            </Modal>
        </>;
    }