import React, { useCallback, useState, useEffect, useLayoutEffect, useRef, useContext } from "react";
import { Button, Col, Input, Row } from "reactstrap";
import { Field } from "./Fields";

const ActiveContext = React.createContext(null as any);

function useImage(src) {
	const [ image, setImage ] = useState<HTMLImageElement>();
	
	useEffect(function() {
		load_img(src)
			.then(setImage);
	}, [src]);
	
	return image;
}

export default function Placemarker({ img, children }) {
	const activeState = useState(-1);
	const [ active, setActive ] = activeState;
	
	const image = useImage(img);
	
	const canvasRef = useRef<any>();
		
	const update = useCallback(function() {
		const canvas = canvasRef.current;
		
		if (!image || !canvas)
			return false;
			
		const canvasContext = canvas.getContext('2d');
		canvasContext.clearRect(0, 0, canvas.width, canvas.height);
		canvasContext.globalAlpha = 1;
		canvasContext.drawImage(image, 0, 0);
		
		for (const child of React.Children.toArray(children)) {
			const props = (child as any).props;
			if (props.value) {
				canvasContext.beginPath();
				canvasContext.rect(props.value.x1, props.value.y1, props.value.x2 - props.value.x1, props.value.y2 - props.value.y1);
				
				canvasContext.globalAlpha = .8;
				
				canvasContext.strokeStyle = props.color;
				canvasContext.stroke();
				
				canvasContext.globalAlpha = .2;
				canvasContext.fillStyle = props.color;
				canvasContext.fill();
			}
		}
		
	}, [ image, children, canvasRef ]);

	useEffect(function() {
		update();
	}, [ update ]);
	
	const activeChild = active >= 0 && children[active];
	
	const setChildProp = useCallback(function(loc) {
		if (activeChild) {
			activeChild.props.onChange(loc);
		}
	}, [ activeChild ])
	
	const [ loc, setLoc ] = useState<any>();
	
	const onMouseDown = useCallback(function(e) {
		setLoc(getMousePos(canvasRef.current, e));
	}, [ setLoc ]);
	
	const onMouseUp = useCallback(function(e) {
		setLoc(null);
	}, [ setLoc ]);
	
	const onMouseMove = useCallback(function(e) {
		if (loc) {
			const cord = getMousePos(canvasRef.current, e);
			setChildProp({ x1: loc.x, y1: loc.y, x2: cord.x, y2: cord.y });
		}
	}, [ loc, active, setChildProp ]);

	return <div>
		<div className="d-flex flex-row justify-content-between mb-3">
			{
				React.Children.map(children, (a,i) => <ActiveContext.Provider value={[ active === i, () => setActive(i === active ? -1 : i) ]}>
					{a}
				</ActiveContext.Provider>)
			}
		</div>
		
		<Row>
			<Col md="8">
				<div className="d-flex justify-content-center">
					<canvas
						width={image?.width}
						height={image?.height}
						ref={canvasRef}
						onMouseDown={onMouseDown}
						onMouseUp={onMouseUp}
						onMouseMove={onMouseMove}
						onMouseLeave={onMouseUp}
						style={{ cursor: active === -1 ? 'inherit' : 'crosshair', width: '100%' }}
					/>
				</div>
			</Col>
			<Col md="4">
				{ activeChild && <MarkEditor {...activeChild.props} />}
			</Col>
		</Row>
	</div>;
}

export function Mark({ name, value, onChange, color, isText }: any) {
	const [ isActive, setActive ] = useContext(ActiveContext);
	
	return <Button color="primary" outline={!isActive} onClick={setActive}>
		<div style={{ display: 'inline-block', width: '10px', height: '10px', borderRadius: '50%', background: color }} /> { name }
	</Button>;
}

export function MarkEditor({ name, value, onChange, color, isText }: any) {
	return <>
		<h3>{name}</h3>
		
		<Row>
			<Col md="6">
				<Field title="X1">
					<Input
						type="text"
						value={(value && value.x1) || ''}
						onChange={a => onChange({ ...(value || {}), x1: a.currentTarget.value })}
					/>
				</Field>
			</Col>
			<Col md="6">
				<Field title="Y1">
					<Input
						type="text"
						value={(value && value.y1) || ''}
						onChange={a => onChange({ ...(value || {}), y1: a.currentTarget.value })}
					/>
				</Field>
			</Col>
			<Col md="6">
				<Field title="X2">
					<Input
						type="text"
						value={(value && value.x2) || ''}
						onChange={a => onChange({ ...(value || {}), x2: a.currentTarget.value })}
					/>
				</Field>
			</Col>
			<Col md="6">
				<Field title="Y2">
					<Input
						type="text"
						value={(value && value.y2) || ''}
						onChange={a => onChange({ ...(value || {}), y2: a.currentTarget.value })}
					/>
				</Field>
			</Col>
		</Row>
		
		{ isText && <Field title="Font Size">
			<Input
				type="text"
				value={(value && +value.font_size) || '13'}
				onChange={a => onChange({ ...(value || {}), font_size: a.currentTarget.value })}
			/>
		</Field> }	
	</>;
}

function getMousePos(canvas, evt) {
	var rect = canvas.getBoundingClientRect();
	return {
		x: (evt.clientX - rect.left) / (rect.right - rect.left) * canvas.width,
		y: (evt.clientY - rect.top) / (rect.bottom - rect.top) * canvas.height
	};
}

function load_img(src) {
	return new Promise<HTMLImageElement>(function(resolve, reject) {
		const img = new Image();
		console.log(src);
		img.src = src;
		img.onload = () => resolve(img);
		img.onerror = () => reject();
	});
}
