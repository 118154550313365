
import {FormGroup, Input, Label, ModalBody, Row, Col, Button, Modal, ModalHeader} from "reactstrap";
import {useEntity, useFetch} from "react-admin-base";
import {CheckBox, ModalEntityEditor, SingleFilePicker, Validator} from 'react-admin-base-bootstrap';
import { useUser } from '../../Components/UserProvider';
import Placemarker, { Mark } from "../../Components/Placemarker";
import { CompanySelect, FontSelect } from "../../Components/Selects";
import { useReducer } from "react";

function OptionsButton({ children, disabled }) {
	const [ isOpen, toggle ] = useReducer(a => !a, false);
	
	return <>
		<Button type="button" color="primary" className="mt-2" size="sm" outline onClick={toggle} disabled={disabled}>
			<i className="fas fa-gear" />
			Options
		</Button>
		{ isOpen && <Modal isOpen size="xl" toggle={toggle}>
			<ModalHeader toggle={toggle}>
				Templating
			</ModalHeader>
			<ModalBody>
				{ children }
			</ModalBody>
		</Modal> }
	</>;
}

function BackgroundOptions({ value, onChange }) {
	return <Placemarker img={process.env.REACT_APP_ENDPOINT?.replace(/\/$/, '') + value.file.access_url}>
		<Mark name="Logo" value={value.logo} onChange={a => onChange({ ...value, logo: a })} color="orange" />
		<Mark name="Product Image" value={value.product_image} onChange={a => onChange({ ...value, product_image: a })} color="red" />
		<Mark name="Website" value={value.website} onChange={a => onChange({ ...value, website: a })} color="green" isText />
		<Mark name="Working Hours" value={value.working_hours} onChange={a => onChange({ ...value, working_hours: a })} color="blue" isText />
		<Mark name="Phone" value={value.phone} onChange={a => onChange({ ...value, phone: a })} color="black" isText />
		<Mark name="Address" value={value.address} onChange={a => onChange({ ...value, address: a })} color="gray" isText />
		<Mark name="Title" value={value.title} onChange={a => onChange({ ...value, title: a })} color="yellow" isText />
		<Mark name="Subtitle" value={value.subtitle} onChange={a => onChange({ ...value, subtitle: a })} color="purple" isText />
		<Mark name="Start Date" value={value.start_date} onChange={a => onChange({ ...value, start_date: a })} color="cyan" isText />
		<Mark name="End Date" value={value.end_date} onChange={a => onChange({ ...value, end_date: a })} color="white" isText />
		<Mark name="Start & End Date" value={value.start_and_end_date} onChange={a => onChange({ ...value, start_and_end_date: a })} color="pink" isText />
		<Mark name="Unit" value={value.unit} onChange={a => onChange({ ...value, unit: a })} color="brown" isText />
		<Mark name="Price" value={value.price} onChange={a => onChange({ ...value, price: a })} color="red" />
	</Placemarker>;
}

function PriceDecorationOptions({ value, onChange }) {
	return <Placemarker img={process.env.REACT_APP_ENDPOINT?.replace(/\/$/, '') + value.file.access_url}>
		<Mark name="Price" value={value.price} onChange={a => onChange({ ...value, price: a })} color="orange" isText />
		<Mark name="Old Price" value={value.old_price} onChange={a => onChange({ ...value, old_price: a })} isText color="red" />
		<Mark name="Unit" value={value.unit} onChange={a => onChange({ ...value, unit: a })} isText color="gray" />
	</Placemarker>;
}

function MultiBackgroundOptions({ value, onChange }) {
	return <Placemarker img={process.env.REACT_APP_ENDPOINT?.replace(/\/$/, '') + value.file.access_url}>
		<Mark name="Logo" value={value.logo} onChange={a => onChange({ ...value, logo: a })} color="orange" />
		<Mark name="Area" value={value.area} onChange={a => onChange({ ...value, area: a })} color="red" />
		<Mark name="Website" value={value.website} onChange={a => onChange({ ...value, website: a })} color="green" isText />
		<Mark name="Working Hours" value={value.working_hours} onChange={a => onChange({ ...value, working_hours: a })} color="blue" isText />
		<Mark name="Phone" value={value.phone} onChange={a => onChange({ ...value, phone: a })} color="black" isText />
		<Mark name="Address" value={value.address} onChange={a => onChange({ ...value, address: a })} color="gray" isText />
		<Mark name="Start Date" value={value.start_date} onChange={a => onChange({ ...value, start_date: a })} color="cyan" isText />
		<Mark name="End Date" value={value.end_date} onChange={a => onChange({ ...value, end_date: a })} color="white" isText />
		<Mark name="Start & End Date" value={value.start_and_end_date} onChange={a => onChange({ ...value, start_and_end_date: a })} color="pink" isText />
	</Placemarker>;
}

function ProductDecorationOptions({ value, onChange }) {
	return <Placemarker img={process.env.REACT_APP_ENDPOINT?.replace(/\/$/, '') + value.file.access_url}>
		<Mark name="Title" value={value.title} onChange={a => onChange({ ...value, title: a })} color="orange" isText />
		<Mark name="Subtitle" value={value.old_price} onChange={a => onChange({ ...value, old_price: a })} isText color="red" />
		<Mark name="Unit" value={value.unit} onChange={a => onChange({ ...value, unit: a })} isText color="blue" />
		<Mark name="Product Image" value={value.product_image} onChange={a => onChange({ ...value, product_image: a })} isText color="green" />
		<Mark name="Price" value={value.price} onChange={a => onChange({ ...value, price: a })} color="cyan" />
	</Placemarker>;
}

export default function TemplateEntity({ url, onReload, id }) {
	const entity = useEntity('/template', id, {
		
	});
	const [data, setData] = entity;
	
	if (!data) {
		return null;
	}
	return <ModalEntityEditor title="Template Management" size="xl" url={url} onReload={onReload} entity={entity}>
		<ModalBody>
			<FormGroup>
				<Label>Name</Label>
				<Input
					type="text"
					value={data.name || ''}
					onChange={b => setData({ name: b.currentTarget.value})}
				/>
			</FormGroup>
			<FormGroup>
				<Label>Multiple Pages</Label>
				<CheckBox
					label="Has Multiple Pages?"
					checked={data.is_multi || ''}
					onChange={b => setData({ is_multi: !data.is_multi })}
				/>
			</FormGroup>
			<Row>
				{ !data.is_multi &&<Col>
					<FormGroup>
						<Label>Page Background</Label>
						<Validator name="background" type="required">
							<SingleFilePicker
								value={data.background && data.background.file}
								onChange={b => setData({ background: b && { file: b, ...(data.background || {}) } })}
							/>
						</Validator>
						<OptionsButton disabled={!data.background}>
							<BackgroundOptions value={data.background} onChange={a => setData({ background: a })} />
						</OptionsButton>
					</FormGroup>
				</Col> }
				{ data.is_multi &&<Col>
					<FormGroup>
						<Label>Page Background</Label>
						<Validator name="background" type="required">
							<SingleFilePicker
								value={data.first_page && data.first_page.file}
								onChange={b => setData({ first_page: b && { file: b, ...(data.first_page || {}) } })}
							/>
						</Validator>
						<OptionsButton disabled={!data.first_page}>
							<MultiBackgroundOptions value={data.first_page} onChange={a => setData({ first_page: a })} />
						</OptionsButton>
					</FormGroup>
				</Col> }
				{ data.is_multi && <Col>
					<FormGroup>
						<Label>Last Page Background</Label>
						<Validator name="last_background" type="required">
							<SingleFilePicker
								value={data.last_page && data.last_page.file}
								onChange={b => setData({ last_page: b && { file: b, ...(data.last_page || {}) } })}
							/>
						</Validator>
						<OptionsButton disabled={!data.last_page}>
							<MultiBackgroundOptions value={data.last_page} onChange={a => setData({ last_page: a })} />
						</OptionsButton>
					</FormGroup>
				</Col> }
				<Col>
					<FormGroup>
						<Label>Price Decoration</Label>
						<Validator name="price_decor" type="required">
							<SingleFilePicker
								value={data.price && data.price.file}
								onChange={b => setData({ price: b && { file: b, ...(data.price || {}) } })}
							/>
						</Validator>
						<OptionsButton disabled={!data.price}>
							<PriceDecorationOptions value={data.price} onChange={a => setData({ price: a })} />
						</OptionsButton>
					</FormGroup>
				</Col>
				{ data.is_multi && <Col>
					<FormGroup>
						<Label>Product Decoration</Label>
						<Validator name="product_decor" type="required">
							<SingleFilePicker
								value={data.product && data.product.file}
								onChange={b => setData({ product: b && { file: b, ...(data.product || {}) } })}
							/>
						</Validator>
						<OptionsButton disabled={!data.product}>
							<ProductDecorationOptions value={data.product} onChange={a => setData({ product: a })} />
						</OptionsButton>
					</FormGroup>
				</Col> }
			</Row>
			<FormGroup>
				<Label>Company</Label>
				<Validator name="company" type="required">
					<CompanySelect
						value={data.company}
						onChange={b => setData({ company: b})}
					/>
				</Validator>
			</FormGroup>
			<FormGroup>
				<Label>Font</Label>
				<Validator name="font" type="required">
					<FontSelect
						value={data.font_family}
						onChange={b => setData({ font_family: b})}
					/>
				</Validator>
			</FormGroup>
			
		</ModalBody>
	</ModalEntityEditor>;
}
