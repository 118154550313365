
import {FormGroup, Input, Label, ModalBody, Row, Col} from "reactstrap";
import {useEntity, useFetch} from "react-admin-base";
import {ModalEntityEditor, SingleFilePicker, Validator} from 'react-admin-base-bootstrap';
import { useUser } from '../../Components/UserProvider';
import { BrandSelect, CategorySelect, CompanySelect } from '../../Components/Selects';
import LanguageEdit from '../../Components/LanguageEdit';

export default function ProductEntity({ url, onReload, id }) {
	const user = useUser();
	const entity = useEntity('/product', id, { names: {} });
	const [data, setData] = entity;
	
	if (!data) {
		return null;
	}

	return <ModalEntityEditor title="Product Management" url={url} onReload={onReload} entity={entity}>
		<ModalBody>
			<FormGroup>
				<Label>Category</Label>
				<Validator name="category" type="required">
					<CategorySelect
						value={data.category}
						onChange={category => setData({ category })}
					/>
				</Validator>
			</FormGroup>
			<FormGroup>
				<Label>Brand</Label>
				<Validator name="brand" type="required">
					<BrandSelect
						value={data.brand}
						onChange={brand => setData({ brand })}
					/>
				</Validator>
			</FormGroup>
			<Row>
				<Col>
					<FormGroup>
						<Label>Quantity</Label>
						<Input
							type="text"
							value={data.quantity || ''}
							onChange={b => setData({ quantity: b.currentTarget.value})}
						/>
					</FormGroup>
				</Col>
				<Col>
					<FormGroup>
						<Label>Unit</Label>
						<Input
							type="text"
							value={data.unit || ''}
							onChange={b => setData({ unit: b.currentTarget.value})}
						/>
					</FormGroup>
				</Col>
			</Row>
			<LanguageEdit value={data.names} onChange={names => setData({names})}>
				{
					(language, value, onChange) => <>
						<FormGroup>
							<Label>Name</Label>
							<Validator name={language.language_code + "_name"} type="required">
								<Input
									type="text"
									value={value && value.name || ''}
									onChange={b => onChange({ ...(value || {}), name: b.currentTarget.value})}
								/>
							</Validator>
						</FormGroup>
						<FormGroup>
							<Label>Subtitle </Label>
							<Validator name={language.language_code + "_subtitle"} type="required">
								<Input
									type="text"
									value={value && value.subtitle || ''}									onChange={b => onChange({ ...(value || {}), subtitle: b.currentTarget.value})}
								/>
							</Validator>
						</FormGroup>
					</>
				}
			</LanguageEdit>
			<FormGroup>
				<Label>Image</Label>
				<SingleFilePicker
					value={data.photo}
					onChange={photo => setData({ photo })}
				/>
			</FormGroup>
		</ModalBody>
	</ModalEntityEditor>;
}
