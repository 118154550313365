import React from 'react';
import { useFetch } from 'react-admin-base';
import { ActionsColumn, Column, CRUD, CRUDActions, IdColumn } from "react-admin-base-bootstrap";
import { Breadcrumb } from 'react-admin-base-nazox';
import { useUser } from '../../Components/UserProvider';
import TemplateEntity from "./TemplateEntity";

export default function Templates() {
	const user = useUser();
	const [ languages ] = useFetch("/template?itemPerPage=-1");
	
	if (!languages)
		return null;
	
	return <Breadcrumb
		title="Templates"
		data={
			[
				{
					href: '/template',
					name: 'Templates'
				}
			]
		}
	>
		<CRUD Component={TemplateEntity} url="/template">
			<thead>
				<tr>
					<IdColumn />
					<Column sort="name">Name</Column>
					<Column>Company</Column>
					<Column sort="created_at">Created at</Column>
					<ActionsColumn />
				</tr>
			</thead>
			<tbody>
			{
				row => <tr key={row.id}>
					<td className="min text-center">{row.id}</td>
					<td>{row.name}</td>
					<td>{row.company.name}</td>
					<td>{new Date(row.created_at).toLocaleString()}</td>
					<CRUDActions
						id={row.id}
						edit
						del={"/template/" + row.id}
					/>
				</tr>
			}
			</tbody>
		</CRUD>
	</Breadcrumb>;
}
