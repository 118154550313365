import React from 'react';
import { useFetch } from 'react-admin-base';
import { ActionsColumn, Column, CRUD, CRUDActions, IdColumn } from "react-admin-base-bootstrap";
import { Breadcrumb } from 'react-admin-base-nazox';
import { useUser } from '../../Components/UserProvider';
import ProductEntity from "./ProductEntity";

export default function Categories() {
	const user = useUser();
	const [ languages ] = useFetch("/language?itemPerPage=-1");
	
	if (!languages)
		return null;
	
	return <Breadcrumb
		title="Products"
		data={
			[
				{
					href: '/product',
					name: 'Products'
				}
			]
		}
	>
		<CRUD Component={ProductEntity} url="/product">
			<thead>
				<tr>
					<IdColumn />
					{ languages.data.map(a => <Column key={a.id}>Name ({a.language_code})</Column>) }
					<Column sort="created_at">Created at</Column>
					<ActionsColumn />
				</tr>
			</thead>
			<tbody>
			{
				row => <tr key={row.id}>
					<td className="min text-center">{row.id}</td>
					{ languages.data.map(a => <td key={a.id}>{ row.names && row.names[a.id] && row.names[a.id].name }</td>) }
					<td>{new Date(row.created_at).toLocaleString()}</td>
					<CRUDActions
						id={row.id}
						edit
						del={"/category/" + row.id}
					/>
				</tr>
			}
			</tbody>
		</CRUD>
	</Breadcrumb>;
}
