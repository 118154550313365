
import { ActionsColumn, Column, CRUD, CRUDActions, IdColumn } from "react-admin-base-bootstrap";
import { Breadcrumb } from 'react-admin-base-nazox';
import CompanyEntity from "./CompanyEntity";

export default function Companies() {
	return <Breadcrumb
		title="Companies"
		data={
			[
				{
					href: '/company',
					name: 'Companies'
				}
			]
		}
	>
		<CRUD Component={CompanyEntity} url="/company">
			<thead>
				<tr>
					<IdColumn />
					<Column sort="name">Name</Column>
					<Column sort="created_at">Created at</Column>
					<ActionsColumn />
				</tr>
			</thead>
			<tbody>
			{
				row => <tr key={row.id}>
					<td className="min text-center">{row.id}</td>
					<td>{row.name}</td>
					<td>{new Date(row.created_at).toLocaleString()}</td>
					<CRUDActions
						id={row.id}
						edit
						del={"/company/" + row.id}
					/>
				</tr>
			}
			</tbody>
		</CRUD>
	</Breadcrumb>;
}
