import { Fragment } from 'react';
import { Relative } from 'react-admin-base-bootstrap';
import { Col, Row } from 'reactstrap';
import { useLanguage } from "./LanguageProvider";

export default function LanguageEdit({ value, onChange, children }) {
	const [,languages] = useLanguage();
	
	return <Fragment>
		{ Object.values(languages)
		.map(a => <Row key={a.id}>
			<Col className="col-auto align-self-center" style={{writingMode: 'vertical-rl', textOrientation: 'mixed'}}>
				{a.icon && <Relative><img width="18" height="18" src={a.icon.access_url} /></Relative>} 
			</Col>
			<Col>
				{children( a, value && value[a.id], b => onChange({ ...(value || {}), [a.id]: b }) )}
			</Col>
		</Row>) }
	</Fragment>;
}
