import React from 'react';
import {FormGroup, Input, Label, ModalBody} from "reactstrap";
import {useEntity} from "react-admin-base";
import {ModalEntityEditor, SingleFilePicker, Validator} from "react-admin-base-bootstrap";

export const roles = {
	'512': 'Super Admin',
	'256': 'Company Admin',
	'128': 'User'
};

export default function CompanyEntity({ url, onReload, id }) {
	const entity = useEntity('/company', id);
	const [data, setData] = entity;

	if (!data) {
		return null;
	}

	return <ModalEntityEditor title="Company Management" url={url} onReload={onReload} entity={entity}>
		<ModalBody>
			<FormGroup>
				<Label>Name</Label>
				<Validator name="name" type="required">
					<Input
						type="text"
						value={data.name || ''}
						onChange={a => setData({name: a.currentTarget.value})}
					/>
				</Validator>
			</FormGroup>
			<FormGroup>
				<Label>Logo</Label>
				<Validator name="logo" type="required">
					<SingleFilePicker
						accepts="image/*"
						value={data.logo}
						onChange={a => setData({logo: a})}
					/>
				</Validator>
			</FormGroup>
		</ModalBody>
	</ModalEntityEditor>;
}
