import { Routes, Route, Navigate } from 'react-router-dom';
import Users from "./pages/User/Users";
import Companies from "./pages/Company/Companies";
import Languages from "./pages/Language/Languages";
import Branches from "./pages/Branch/Branches";
import Categories from "./pages/Category/Categories";
import Products from "./pages/Product/Products";
import Brands from './pages/Brand/Brands';
import Templates from './pages/Template/Templates';
import Campaigns from './pages/Campaign/Campaigns';
import Profile from "./pages/Profile/Edit";

export default function Router() {
    return <Routes>
        <Route path="/user/*" element={<Users />}/>
        <Route path="/profile" element={<Profile />}/>
        <Route path="/company/*" element={<Companies />} />
        <Route path="/language/*" element={<Languages />} />
        <Route path="/branch/*" element={<Branches />} />
        <Route path="/category/*" element={<Categories />} />
        <Route path="/product/*" element={<Products />} />
        <Route path="/brand/*" element={<Brands />} />
        <Route path="/template/*" element={<Templates />} />
        <Route path="/campaign/*" element={<Campaigns />} />

        <Route path="*" element={<Navigate to="/user" />} />
    </Routes>;
}
